<template>
	<div>
		<div class="relative" title="Send ein teldupost">
			<a href="#" @click.prevent.stop="contextMenu.active = !contextMenu.active" class="btn-round far fa-envelope"></a>

			<div class="context-menu" :class="{ active: contextMenu.active }">
				<div class="search-container">
					<input v-model="contextMenu.query" ref="searchInput" type="text" @click.stop placeholder="Leita..." class="search-input" />

					<i class="fas fa-search"></i>
				</div>

				<a v-for="mail in activeMails" :key="mail.id" @click="preview(mail)" href="#">{{ mail.title }}</a>
			</div>
		</div>

		<VOverlay :visible="overlay.active" @close="overlay.active = false">
			<div class="flex space-between align-center mb-30">
				<h1 class="mb-10">Send teldupost</h1>

				<div class="overlay-close" @click.prevent="overlay.active = false"></div>
			</div>

			<form @submit.prevent="send">
				<label class="mb-20">
					<div class="mb-10">Móttakari / Móttakarar</div>

					<input v-model="overlay.form.receivers" type="text" />

					<div v-if="!emailsAreValid" class="small-text color-red mt-10 text-normal">Einans skriva teldupostar í her</div>
				</label>

				<label class="mb-20">
					<div class="mb-10">Móttakari / Móttakarar</div>

					<input v-model="overlay.form.cc" type="text" />

					<div v-if="!emailsAreValid" class="small-text color-red mt-10 text-normal">Einans skriva teldupostar í her</div>
				</label>

				<label class="mb-20">
					<div class="mb-10">Evni</div>

					<input v-model="overlay.form.subject" type="text" />
				</label>

				<label class="mb-20">
					<div class="mb-10">Subject</div>

					<textarea v-model="overlay.form.body" ref="textarea" rows="5"></textarea>
				</label>

				<!-- Attachments -->
				<template v-if="(files && files.length) || (otherFiles && otherFiles.length)">
					<label class="mb-10">Viðheft fílur</label>

					<div class="mb-10" style="max-height: 224px; overflow-y: auto">
						<div v-for="file in files" :key="file.id" class="flex space-between mb-5 p-15 bg-white border rounded">
							<div>{{ decodeURI(file.name) }}</div>

							<input type="checkbox" v-model="file.checked" class="cursor-pointer" />
						</div>

						<div v-if="!files.length" class="my-20 color-grey text-center">Avtalan hevur ongar fílur enn.</div>

						<template v-if="otherFiles && otherFiles.length">
							<hr class="my-30" />

							<label class="mb-10">Aðrar fílur</label>

							<div class="mb-10">
								<div v-for="file in otherFiles" :key="file.id" class="flex space-between mb-5 p-15 bg-white border rounded">
									<div>{{ decodeURI(file.name) }}</div>

									<input type="checkbox" v-model="file.checked" class="cursor-pointer" />
								</div>
							</div>
						</template>
					</div>
				</template>

				<div v-if="!isValid" class="small-text color-red mb-20" v-text="errorMessage"></div>

				<div class="flex justify-end">
					<button class="btn" :class="{ disabled: $v.$invalid || !isValid || !emailsAreValid || overlay.loading }">
						{{ overlay.loading ? 'Bíða við...' : 'Send' }}
					</button>
				</div>
			</form>
		</VOverlay>
	</div>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';

import VOverlay from '@/components/VOverlay.vue';

export default {
	name: 'Mail',

	props: {
		customer: Object,
		terminal: Object,
		agreement: Object,
		files: Array,
		type: String,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			mails: [],

			contextMenu: {
				active: false,
				query: '',
			},

			overlay: {
				loading: false,
				active: false,
				mail: null,

				form: {
					receivers: '',
					cc: '',
					subject: '',
					body: '',
				},
			},

			otherFiles: [],
		};
	},

	mounted() {
		axios
			.get(`${process.env.VUE_APP_TERMINAL_API_URL}/actions`)
			.then((response) => {
				this.mails = response.data;

				if (this.type) {
					this.mails = this.mails.filter((mail) => mail.type == this.type);
				}
			})
			.catch((error) => {
				console.log('Error fetching actions in Mail.vue', error);
			});

		if (this.type == 'Sáttmála' && this.customer) {
			axios
				.get(`${process.env.VUE_APP_TERMINAL_API_URL}/files/customer/${this.customer.id}`)
				.then((response) => {
					this.otherFiles = response.data;

					const fileIds = this.files.map((file) => file.id);

					this.otherFiles = this.otherFiles.filter((file) => {
						return !fileIds.includes(file.id);
					});
				})
				.catch((error) => {
					console.log('Error customer files in Mail.vue', error);
				});
		}

		window.addEventListener('click', () => {
			this.contextMenu.active = false;
		});
	},

	computed: {
		isValid() {
			return (
				!this.hasPlaceholder(this.overlay.form.receivers) &&
				!this.hasPlaceholder(this.overlay.form.subject) &&
				!this.hasPlaceholder(this.overlay.form.body)
			);
		},

		errorMessage() {
			if (!this.terminal && !this.agreement) {
				return 'Telduposturin er ikki rætt konverteraður. Tað er ongin máti at vita, hvør terminalur, ella hvør sáttmáli, talan er um.';
			}

			if (!this.terminal) {
				return 'Telduposturin er ikki rætt konverteraður. Tað er ongin máti at vita, hvør terminalur, talan er um.';
			}

			if (!this.agreement) {
				return 'Telduposturin er ikki rætt konverteraður. Tað er ongin máti at vita, hvør sáttmáli, talan er um.';
			}

			return 'Telduposturin er ikki rætt konverteraður. Skermurin hevur ikki allar neyðugu upplýsingarnar.';
		},

		activeMails() {
			return this.mails.filter((mail) => mail.title?.includes(this.contextMenu.query));
		},

		emailsAreValid() {
			for (const email of this.overlay.form.receivers.split(',')) {
				if (!email) {
					continue;
				}

				if (!this.isEmail(email.trim())) {
					return false;
				}
			}

			return true;
		},

		allFiles() {
			return [...(this.files || []), ...(this.otherFiles || [])];
		},
	},

	watch: {
		'contextMenu.active': function (value) {
			if (value) {
				this.$refs.searchInput.focus();
			}
		},
	},

	methods: {
		searchReplace(string) {
			if (!string) {
				return;
			}

			if (this.customer) {
				string = string
					.replace(/\{customerRegistrationNumber\}/g, this.customer.registrationNumber ?? '')
					.replace(/\{customerName\}/g, this.customer.name ?? '')
					.replace(/\{customerVTal\}/g, this.customer.vtal ?? '')
					.replace(/\{customerEmail\}/g, this.customer.email ?? '')
					.replace(/\{customerPhoneNumber\}/g, this.customer.phoneNumber ?? '')
					.replace(/\{customerAddress\}/g, this.customer.address ?? '')
					.replace(/\{customerZip\}/g, this.customer.zip ?? '')
					.replace(/\{customerCity\}/g, this.customer.city ?? '')
					.replace(/\{customerMcc\}/g, this.customer.mcc ?? '')
					.replace(/\{customerTCSUsername\}/g, this.customer.tcs_username ?? '')
					.replace(/\{customerTCSEmail\}/g, this.customer.tcs_email ?? '')
					.replace(/\{customerTCSPhone\}/g, this.customer.tcs_phone ?? '');
			}

			if (this.terminal) {
				string = string
					.replace(/\{terminalVerifoneNumber\}/g, this.terminal.verifoneNumber ?? '')
					.replace(/\{terminalVerifoneSerialNumber\}/g, this.terminal.verifoneSerialnumber ?? '')
					.replace(/\{terminalPhoneNumber\}/g, this.terminal.phoneNumber ?? '')
					.replace(/\{terminalPSAM\}/g, this.terminal.psam ?? '')
					.replace(/\{terminalICC\}/g, this.terminal.icc ?? '')
					.replace(/\{terminalCradleNumber\}/g, this.terminal.cradleNumber ?? '');
			}

			if (this.agreement) {
				const bankAccount = this.agreement.bankAccount
					? `${this.agreement.bankAccount.registrationNumber} ${this.agreement.bankAccount.accountNumber}`
					: '';

				string = string
					.replace(/\{agreementType\}/g, this.agreement.type?.name ?? '')
					.replace(/\{agreementStepList\}/g, this.agreement.stepList?.name ?? '')
					.replace(/\{agreementStartDate\}/g, new Date(this.agreement.from).format('%d/%m/%y kl. %H:%M') ?? '')
					.replace(/\{agreementEndDate\}/g, new Date(this.agreement.to).format('%d/%m/%y kl. %H:%M') ?? '')
					.replace(/\{agreementTOF\}/g, this.agreement.tof_number ?? '')
					.replace(/\{agreementEcom\}/g, this.agreement.swedbank_e_com_number ?? '')
					.replace(/\{agreementPOS\}/g, this.agreement.swedbank_pos_number ?? '')
					.replace(/\{agreementLink\}/g, `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${this.agreement?.agreementLink?.token}` ?? '')
					.replace(/\{agreementAccountNumber\}/g, bankAccount);
			}

			return string;
		},

		preview(mail) {
			this.overlay.active = true;

			this.overlay.form.receivers = this.searchReplace(mail.to);
			this.overlay.form.cc = this.searchReplace(mail.cc);
			this.overlay.form.subject = this.searchReplace(mail.subject);
			this.overlay.form.body = this.searchReplace(mail.message);
		},

		closeOverlay() {
			this.overlay.active = false;
			this.overlay.mail = null;
		},

		hasPlaceholder(string) {
			return /\{[a-zA-Z]*\}/.test(string);
		},

		isEmail(email) {
			return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email
			);
		},

		send() {
			this.overlay.loading = true;

			let attachments = [];

			if (this.allFiles) {
				attachments = this.allFiles.filter((file) => file.checked).map((file) => file.uuid);
			}

			axios
				.post(`/emails/`, {
					to: this.overlay.form.receivers
						.split(',')
						.filter((email) => !!email)
						.map((email) => email.trim()),

					subject: this.overlay.form.subject,
					body: this.overlay.form.body,
					attachments,
				})
				.then(() => {
					this.overlay.form.to = '';
					this.overlay.form.subject = '';
					this.overlay.form.body = '';

					this.overlay.active = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.overlay.loading = false;
				});
		},
	},

	validations: {
		overlay: {
			form: {
				receivers: { required },
				subject: { required },
				body: { required },
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.context-menu {
	position: absolute;
	top: 100%;
	right: 0;
	width: 280px;
	max-height: 240px;

	background: white;

	border: 1px solid #e8e8e8;
	border-radius: 8px;

	overflow-y: auto;

	box-shadow: 0 2px 3px rgba(black, 0.05);

	transition: 0.2s all ease;

	&:not(.active) {
		transform: translateY(-8px);
		opacity: 0;
		pointer-events: none;
	}

	a {
		display: block;
		padding: 16px;

		border-top: 1px solid #f2f2f2;

		&:hover {
			background: #f2f2f2;
		}
	}
}

.search-container {
	position: relative;

	padding: 12px;

	i {
		position: absolute;
		top: 50%;
		right: 22px;
		transform: translateY(-50%);

		color: #828282;
	}
}

.search-input {
	padding: 8px 12px;
	box-shadow: none;
	width: 100%;
	box-sizing: border-box;
}

textarea {
	resize: none;
}
</style>
