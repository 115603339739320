<template>
	<div class="modal flex justify-center align-start overflow-auto" :class="{ visible: visible }" @click="close">
		<div v-if="visible" class="modal-container background-white rounded-lg">
			<div class="container-sm">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VOverlay',

	props: {
		visible: Boolean,
	},

	mounted() {
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				this.$emit('close');
			}
		});
	},

	methods: {
		close(e) {
			if (!e.target.classList.contains('modal')) {
				return;
			}

			this.$emit('close');
		},
	},
};
</script>
